import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, Button, DialogActions, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
export default function InvoiceDialog(props) {
  const { onClose, onSubmit } = props;

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    invoiceName: props.initialFormData.invoiceName,
    invoiceVatNumber: props.initialFormData.invoiceVatNumber,
    invoiceAddress: props.initialFormData.invoiceAddress,
    invoicePostcode: props.initialFormData.invoicePostcode,
    invoiceCity: props.initialFormData.invoiceCity
  });

  const [errors, setErrors] = useState({
    invoiceName: false,
    invoiceVatNumber: false,
    invoiceAddress: false,
    invoicePostcode: false,
    invoiceCity: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate input
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let isValid = true;
    if (name === 'invoiceVatNumber') {
      isValid = validateVatNumber(value);
    } else {
      isValid = value !== null && value.trim() !== '';
    }
    setErrors({
      ...errors,
      [name]: !isValid
    });
  };

  const validateVatNumber = (vatNumber) => {
    // VAT number validation with optional country code
    const vatRegex = /^([A-Z]{2})?[A-Z0-9]{8,12}$/; // Optional country code
    return vatRegex.test(vatNumber);
  };

  const isFormValid = () => {
    return Object.values(formData).every(value => value !== null && value.trim() !== '') &&
           Object.values(errors).every(error => !error);
  };
  
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formData);
    }
  };

  return (
    <Dialog open={props.open} classes={{ root: classes.root }}>
      <DialogTitle>{t('invoiceDetails')}</DialogTitle>
      <DialogContent>
        <TextField
          label={t('invoiceName')}
          name="invoiceName"
          fullWidth
          value={formData.invoiceName}
          onChange={handleChange}
          error={errors.invoiceName}
          helperText={errors.invoiceName ? t('errorEmptyField') : ''}
        />
        <TextField
          label={t('invoiceVatNumber')}
          name="invoiceVatNumber"
          fullWidth
          value={formData.invoiceVatNumber}
          onChange={handleChange}
          error={errors.invoiceVatNumber}
          helperText={errors.invoiceVatNumber ? t('errorInvalidVatNumber') : ''}
        />
        <TextField
          label={t('invoiceAddress')}
          name="invoiceAddress"
          fullWidth
          value={formData.invoiceAddress}
          onChange={handleChange}
          error={errors.invoiceAddress}
          helperText={errors.invoiceAddress ? t('errorEmptyField') : ''}
        />
        <TextField
          label={t('invoicePostcode')}
          name="invoicePostcode"
          fullWidth
          value={formData.invoicePostcode}
          onChange={handleChange}
          error={errors.invoicePostcode}
          helperText={errors.invoicePostcode ? t('errorEmptyField') : ''}
        />
        <TextField
          label={t('invoiceCity')}
          name="invoiceCity"
          fullWidth
          value={formData.invoiceCity}
          onChange={handleChange}
          error={errors.invoiceCity}
          helperText={errors.invoiceCity ? t('errorEmptyField') : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isFormValid()}
          onClick={handleSubmit}
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
