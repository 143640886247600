import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from 'react-i18next';
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import {withStyles} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import red from '@material-ui/core/colors/red';
import BusinessDialog from "./InvoiceDialog";

const styles = {
  labelAsterisk: {
    color: red[500],
  },
  card: {
    marginTop: 20,
  },
};

class UserForm extends React.Component {
  state = {
    dialogOpen: false,
    wantInvoice: this.props.initialFormData.wantInvoice,
  };

  handleBlur = () => {
    this.props.updateEmail();
  };

  handleSwitchChange = () => {
    if (!this.state.wantInvoice) {
      this.setState({ dialogOpen: true });
    } else {
      this.setState({ dialogOpen: false, wantInvoice: false });
      this.props.updateInvoice({wantInvoice: false});
    }
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false, wantInvoice: false });
    this.props.updateInvoice({wantInvoice: false});
  };

  handleDialogSubmit = (formData) => {
    this.setState({ dialogOpen: false, wantInvoice: true });
    this.props.updateInvoice({...formData, wantInvoice: true});
  };

  render() {
    const { t, classes } = this.props;
    return(
      <>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={
                    <span>
                      <span className={classes.labelAsterisk}>* </span>
                      {t('enterYourEmail')}
                    </span>
                  }
                  value={this.props.emailInput || ''}
                  error={this.props.errorMessage !== null}
                  onChange={this.props.onEmailChange}
                  onBlur={this.handleBlur}
                  margin="none"
                  fullWidth={true}
                  helperText={this.props.errorMessage !== null ? t(this.props.errorMessage) : null}
                  inputProps={{
                    'data-cy': 'emailInput',
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch onChange={this.handleSwitchChange} checked={this.state.wantInvoice} />}
                    label={t('wantInvoice')}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <BusinessDialog open={this.state.dialogOpen} onClose={this.handleDialogClose} onSubmit={this.handleDialogSubmit} initialFormData={this.props.initialFormData}/>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(UserForm));
