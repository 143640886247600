import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import update from 'react-addons-update'; // ES6
import TitleBar from './TitleBar';
import PrinterBasicInfo from './PrinterBasicInfo';
import Printjobs from './Printjobs';
import PrintButton from './PrintButton';
import PaymentForm from './PaymentForm';
import Summary from './Summary';
import InfoBar from './InfoBar';
import Footer from './Footer';
import { withTranslation } from 'react-i18next';
import request from "superagent";
import {withStyles} from "@material-ui/core";
import AppContent from "./AppContent";
import Dropzone from "react-dropzone";
import logo from "../logo.svg";
import UserForm from "./UserForm";
import * as EmailValidator from 'email-validator';
import PrinterAdServerZone from './PrinterAdServerZone';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { v4 as uuid } from 'uuid';
import { useParams } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import oncePerTransaction from '../utils/OncePerTransaction'
import PopupPaymentCompleted from "./UIelements/Popup";

function withParams(Component) {
  // eslint-disable-next-line react/display-name
  return props => <Component {...props} params={useParams()} />;
}

const styles = theme => ({
  fullscreenDropzone: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullscreenDropzoneLogo: {
    height: 120,
    marginBottom: 16,
  },
  progressWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
  },
});

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreatedByMail: null,
      printjobs: [],
      payment: {
        summary: {
          currency: 'PLN',
          prices: {
            monochrome: 0.20,
            color: 1.20,
            service: 0.00
          },
          pageCount: {
            monochrome: 0,
            color: 0
          }
        },
        bt_token: null,
      },
      uploadedFiles: [],
      emailInput: null,
      emailErrorMessage: null,
      printDialogOpened: true,
      emailInfoDialogOpened: false,
      provider: null,
      paidAmount: 0,
    };

    this.getValidPrintjobs = this.getValidPrintjobs.bind(this);
    this.orderUrl = this.orderUrl.bind(this);
    this.printOrderLater = this.printOrderLater.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handleDropFiles = this.handleDropFiles.bind(this);
    this.handleDropKeyDown = this.handleDropKeyDown.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.closePrintDialog = this.closePrintDialog.bind(this);
    this.toggleEmailInfoDialog = this.toggleEmailInfoDialog.bind(this);

    this.emailFormRef = React.createRef();

    this.props.i18n.on('languageChanged', this.updateLanguage);
  }
  render() {
    const {t, classes} = this.props;
    const orderStatus = this.orderStatus();
    const paymentCompleted = !this.state.payment.needed && ['payment_completed', 'ready'].includes(orderStatus);

    if(paymentCompleted) {
      oncePerTransaction(this.props.params.orderId, 'purchase', () => {
        ReactGA.event('purchase', {
          currency: this.state.payment.summary.currency,
          value: this.state.payment.paidAmount,
          payment_provider: this.state.payment.provider,
          transaction_id: this.props.params.orderId,
          items: {
            item_name: 'express_order',
          },
          printer_id: this.state.printer.id,
        });
        ReactPixel.fbq(
          'track',
          'Purchase',
          {
            currency: this.state.payment.summary.currency,
            value: this.state.payment.paidAmount,
            content_type: 'express_order',
          },
          {
            eventID: `${this.props.params.orderId}.Purchase`
          }
        );
      });
    } else {
      oncePerTransaction(this.props.params.orderId, 'created', () => {
        ReactGA.event({
          category: 'Order',
          action: 'created'
        });
      });
    }

    return (
      <Dropzone
        onDrop={this.handleDropFiles}
        noClick
      >
        { ({getRootProps, getInputProps, isDragActive}) => {
          return (
            <div {...getRootProps()} style={{position: "relative"}}>
              <div onKeyDown={this.handleDropKeyDown}>
                <input
                  {...getInputProps()}
                  data-cy="dropzone"
                />
                {isDragActive &&
                <div className={classes.fullscreenDropzone}>
                  <img src={logo} alt="logo" className={classes.fullscreenDropzoneLogo}/>
                  <span style={{fontSize: 24}}>{t('documentAddDropzoneInfo')}</span>
                </div>
                }
                <AppContent className="content">
                  <TitleBar />
                  {orderStatus != 'not_found' &&
                  <>
                    <PrinterBasicInfo printer={this.state.printer} />
                    { (!this.state.printer || this.state.printer.enabled || this.state.printer.isServiced || (this.state.status === 'print_requested' && this.state.printer.status === 'err_job_timeout')) ||
                    <InfoBar
                      caption={t('printerNotAvailable')}
                      type="error"
                      number={<a style={{color: "white"}} href="tel:+48530657000">48 530657000.</a>} />
                    }
                    { this.state.printer && this.state.printer.isServiced &&
                    <InfoBar
                      caption={t('printerServiced')}
                      type="error" />
                    }
                    <Printjobs
                      colorEnabled={this.state.printer && this.state.printer.isColor}
                      disabled={!['ready', 'calculating'].includes(this.state.status)}
                      onSettingsChanged={this.onSettingsChanged}
                      orderUrl={this.orderUrl}
                      updateOrder={this.updateOrder}
                      uploadFiles={this.uploadFiles}
                      printer={this.state.printer}
                      printjobs={this.state.printjobs}
                      uploadedFiles={this.state.uploadedFiles}
                      emptyMessage={this.state.uploadedFiles.length === 0 && orderStatus === 'empty'}
                    />

                    <Summary
                      onDiscountCodeApplied={this.onDiscountCodeApplied}
                      discountCodeSubmitUrl={this.orderUrl('/discount_code')}
                      disabled={orderStatus !== 'ready' || this.state.payment.summary.discountCode}
                      summary={this.state.payment.summary}
                      validateEmail={this.validateEmail}
                      scrollToEmail={this.scrollToEmail}
                      updateEmail={this.updateEmail}
                    />

                    {this.state.uploadedFiles.length === 0 && this.state.isCreatedByMail === false && ['ready', 'payment_created'].includes(orderStatus) &&
                    <div ref={this.emailFormRef}>
                      <UserForm
                        emailInput={this.state.emailInput}
                        errorMessage={this.state.emailErrorMessage}
                        onEmailChange={this.handleEmailChange}
                        updateEmail={this.updateEmail}
                        updateInvoice={this.updateOrderRequest}
                        initialFormData={this.initialFormData()}
                      />
                    </div>
                    }

                    {this.state.uploadedFiles.length === 0 && ['ready', 'payment_created', 'payment_completed'].includes(orderStatus) &&
                      <PaymentForm
                        currency={this.state.payment.summary.currency}
                        blikSubmitUrl={this.orderUrl('/payment')}
                        przelewy24SubmitUrl={this.orderUrl('/payment')}
                        disabled={!this.state.printer.enabled}
                        paymentCompleted={!this.state.payment.needed}
                        btTokenUrl={this.orderUrl('/payment/new')}
                        paymentStatus={this.state.payment.status}
                        onPaymentCompleted={this.onPaymentCompleted}
                        handlePaymentMethod={this.handlePaymentMethod}
                        validateEmail={this.validateEmail}
                        scrollToEmail={this.scrollToEmail}
                        updateEmail={this.updateEmail}
                        paymentMethods={this.paymentMethods()}
                        printer = {this.state.printer}
                      />
                    }
                  </>}

                  {orderStatus === 'not_found' &&
                    <InfoBar
                      caption={t('orderNotFound')}
                      type="error" />
                  }
                  {orderStatus === 'error' &&
                    <InfoBar
                      caption={t('networkError')}
                      type="error" />
                  }
                  {orderStatus === 'loading' &&
                    <InfoBar
                      caption={t('orderLoading')}
                      type="info" />
                  }
                  {orderStatus === 'print_requested' &&
                    <InfoBar
                      caption={t('orderProcessing')}
                      type="info" />
                  }
                  {orderStatus === 'failed' &&
                    <InfoBar
                      caption={t('orderFailed')}
                      type="error" />
                  }
                  {orderStatus === 'completed' &&
                    <InfoBar
                      caption={t('orderCompleted')}
                      type="success" />
                  }
                  {orderStatus === 'completed' &&
                    <PopupPaymentCompleted printer={this.state.printer} />
                  }
                  {paymentCompleted &&
                    <React.Fragment>
                      <PrintButton onClick={this.printOrder} disabled={!this.state.printer.enabled}/>

                      <Dialog
                        open={this.state.printDialogOpened && !this.state.isFree}
                      >
                        <DialogContent>
                          <DialogContentText>
                            {t('orderPrintDialogMessage')}
                          </DialogContentText>
                          <br/>
                          <DialogContentText>
                            {t('termsAndConditions1')} <a href="https://zeccer.pl/pdf/regulamin.pdf">{t('termsOfService')}</a> {t('and')} <a href="https://zeccer.pl/pdf/polityka-prywatnosci.pdf">{t('privacyPolicy')}</a> {t('termsAndConditions2')}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.printOrderLater} color="primary">
                            {t('printLater')}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.printOrder}
                            disabled={!this.state.printer.enabled}
                          >
                            {t('printNow')}
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <Dialog
                        open={this.state.emailInfoDialogOpened}
                      >
                        <DialogContent>
                          <DialogContentText>
                            {t('orderEmailInfoDialogMessage')}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.toggleEmailInfoDialog}
                          >
                            {t('ok')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment>
                  }

                  {this.state.printer &&
                    <PrinterAdServerZone printerId={this.state.printer.id} />
                  }

                  <Footer />
                </AppContent>
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }

  totalPageCount() {
    var pageCount = 0;
    this.state.printjobs.forEach(function(printjob) {
      pageCount += printjob.page_count;
    });

    return pageCount;
  }

  orderStatus() {
    if (this.state.error !== undefined) {
      switch(this.state.status) {
        case 404:
          return 'not_found';
        default:
          return 'error';
      }
    }

    if (this.state.printer === null) {
      return 'loading';
    }

    if (this.getValidPrintjobs().length === 0) {
      return 'empty';
    }

    if (this.state.printRequestSent) {
      return 'processing';
    }

    return this.state.status;
  }

  getValidPrintjobs() {
    return this.state.printjobs.filter(printjob => !printjob.documents[0].is_failed);
  }

  apiUrl(path) {
    return process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + path;
  }

  orderUrl(path) {
    return this.apiUrl('/orders/' + this.props.params.orderId + path);
  }

  // TODO get rid of callbacks and use Flux?
  // Arrow function is bound to component
  onPaymentCompleted = payload => {
    this.setState(payload);
  }

  onDiscountCodeApplied = code => {
    console.log("code_applied: " + code);
    const newState = update(this.state, {
      payment: {
        summary: {
          discountCode: {
            $set: code
          }
        }
      }
    });
    this.setState(newState);
  }

  handlePaymentMethod = (payload) => {
    payload.provider = 'braintree';
    fetch(this.orderUrl('/payment'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState(json);
    });
  };

  printOrder = () => {
    ReactGA.event({
      category: 'Printjob',
      action: 'enqueued'
    });

    this.setState({printRequestSent: true});

    // Update email before printing
    this.updateEmail(() => {
      fetch(this.orderUrl('/print'), {
        method: 'POST'
      }).then(function(response) {
        return response.json();
      }).then((json) => {
        this.setState(json);
        this.setState({printRequestSent: false});
      });
    });
  };

  printOrderLater() {
    this.closePrintDialog();
    this.toggleEmailInfoDialog();
  }

  onSettingsChanged = (changes, id) => {
    fetch(this.orderUrl('/printjobs/' + id + '/settings'), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(changes)
    }).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState(json);
    });
  }

  getOrder() {
    return this.getOrderPathAndUpdateState('');
  }

  getOrderPath(path) {
    return fetch(this.orderUrl(path))
      .then(function(response) {
        return response.json();
      });
  }

  getOrderPathAndUpdateState(path) {
    return this.getOrderPath(path).then((json) => {
      var newState = {
        ...this.state,
        ...json
      }
      if (JSON.stringify(newState) !== JSON.stringify(this.state)) {
        this.setState(newState);
      }
      return json;
    }).catch(function(ex) {
      console.log('parsing failed', ex);
    });
  }

  updateOrder(data) {
    this.setState(data);
  }

  uploadFiles(files) {
    let uploadedFiles = [];

    files.forEach(file => {
      ReactGA.event({
        category: 'File',
        action: 'selected'
      });
      const id = uuid();
      uploadedFiles.push({
        id: id,
        file: file,
        uploadingError: null,
        uploadingProgress: 0,
      });
    });

    this.setState({
      uploadedFiles: [...this.state.uploadedFiles, ...uploadedFiles],
    }, () => {
      uploadedFiles.forEach((uploadedFile) => {
        const id = uploadedFile.id;

        const req = request.post(this.orderUrl('/printjobs'));

        req.attach('files[]', uploadedFile.file);

        req.on('progress', (event) => {
          if (event.percent !== undefined) {

            // Update file uploading progress
            this.setState({
              uploadedFiles: this.state.uploadedFiles.map(file => {
                if (file.id !== id) {
                  return file;
                }

                file.uploadingProgress = event.percent;

                return file;
              })
            });
          }
        });

        req.end((err, res) => {
          if (res !== undefined && res.statusCode === 200) {
            ReactGA.event({
              category: 'File',
              action: 'uploaded'
            });
            // Remove uploaded file and update printjobs
            this.setState({
              uploadedFiles: this.state.uploadedFiles.filter(uploadedFile => uploadedFile.id !== id),
              printjobs: res.body.printjobs,
              status: res.body.status,
            });
          } else {
            // Update file uploading error
            this.setState({
              uploadedFiles: this.state.uploadedFiles.map(file => {
                if (file.id !== id) {
                  return file;
                }

                file.uploadingError = true;

                return file;
              })
            }, () => {
              // Remove uploaded file after some time
              setTimeout(() => {
                this.setState({
                  uploadedFiles: this.state.uploadedFiles.filter(uploadedFile => uploadedFile.id !== id),
                });
              }, 5000);
            });
          }
        });
      });
    });
  }

  handleDropFiles(files) {
    this.uploadFiles(files);
  }

  handleDropKeyDown(event) {
    event.stopPropagation();
  }

  handleEmailChange(event) {
    this.setState({
      emailInput: event.target.value.trim(),
    }, () => {
      // Validate emailInput if is invalid
      if (this.state.emailErrorMessage !== null) {
        this.validateEmail();
      }
    });
  }

  validateEmail() {
    if (this.state.isCreatedByMail === false) {
      if (!EmailValidator.validate(this.state.emailInput)) {
        this.setState({emailErrorMessage: 'emailIsInvalid'});
        return false;
      }

      if (!this.state.emailInput) {
        this.setState({emailErrorMessage: 'emailCanNotBeEmpty'});
        return false;
      }
    }

    this.setState({emailErrorMessage: null});
    return true;
  }

  updateEmail = (callback) => {
    if (this.validateEmail()) {
      this.updateOrderRequest({ email: this.state.emailInput }, callback);
    }
  };

  updateOrderRequest = (formData, callback) => {
    fetch(this.orderUrl(''), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    }).then(function (response) {
      return response.json();
    }).then((json) => {
      this.setState(json);

      if (callback !== undefined) {
        callback();
      }
    });
  };

  scrollToEmail = () => {
    this.emailFormRef.current.scrollIntoView({'behavior': 'smooth'});
  };

  closePrintDialog() {
    this.setState({printDialogOpened: false});
  }

  toggleEmailInfoDialog() {
    this.setState({emailInfoDialogOpened: !this.state.emailInfoDialogOpened});
  }

  updateLanguage = () => {
    fetch(this.orderUrl(''), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language: this.props.i18n.language,
      })
    }).then(function (response) {
      return response.json();
    }).then((json) => {
      this.setState(json);
    });
  };

  componentDidMount() {
    this.getOrder().then((json) => {
      if(json.error === undefined) {
        this.setInterval();

        // Update order language
        this.updateLanguage();
      }

      // Set initial email input value
      if (json.email) {
        this.setState({
          emailInput: json.email,
        });
      }
    });
  }

  setInterval() {
    this.setState({intervalId: setInterval(this.tick, 5000)});
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.state.intervalId);
  }

  tick = () => {
    this.getOrder();
  }

  // This should come from BE
  paymentMethods() {
    var blik_method = process.env.REACT_APP_USE_PRZELEWY24_BLIK == 'true' ? 'p24_blik' : 'blik';
    var methods = ['braintree'];
    var country = this.state.printer.addressDetails.country;

    if (country == 'PL') {
      methods.push(blik_method);
      methods.push('p24');
    } else if (country == 'DE') {
      methods.push('sofort');
    }

    return methods;
  }

  initialFormData() {
    return {
      wantInvoice: this.state.wantInvoice,
      invoiceName: this.state.invoiceName,
      invoiceVatNumber: this.state.invoiceVatNumber,
      invoiceAddress: this.state.invoiceAddress,
      invoicePostcode: this.state.invoicePostcode,
      invoiceCity: this.state.invoiceCity,
    };
  }
}

export default withParams(withStyles(styles)(withTranslation()(Order)));
