import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PrinterRedirect = () => {
  const { printerEmail } = useParams();
  const newHost = process.env.REACT_APP_LP_HOST;

  useEffect(() => {
    if (printerEmail) {
      if (newHost) {
        window.location.href = `${newHost}/punkty-druku/drukarka/${printerEmail}`;
      } else {
        window.location.href = `/printers/${printerEmail}/orders/new`;
      }
    }
  }, [printerEmail, newHost]);

  return null; // No UI needed since this component only handles redirection
};

export default PrinterRedirect;
